var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c(
        "div",
        { staticClass: "form-section" },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-MONTHLY_NO_ACTIVATION_MSG",
                    label: "Monthly No Record Message",
                    "label-for": "input-MONTHLY_NO_ACTIVATION_MSG",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "input-MONTHLY_NO_ACTIVATION_MSG",
                      required: "",
                      rows: "3",
                      "max-rows": "8",
                      placeholder: "Enter Monthly Note",
                    },
                    model: {
                      value: _vm.item.MONTHLY_NO_ACTIVATION_MSG,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "MONTHLY_NO_ACTIVATION_MSG", $$v)
                      },
                      expression: "item.MONTHLY_NO_ACTIVATION_MSG",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-DAILY_NOTIFICATION_TITLE_MSG",
                    label: "Daily Notification Title",
                    "label-for": "input-DAILY_NOTIFICATION_TITLE_MSG",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-DAILY_NOTIFICATION_TITLE_MSG",
                      type: "text",
                      required: "",
                      placeholder: "Enter Daily Notification Title",
                    },
                    model: {
                      value: _vm.item.DAILY_NOTIFICATION_TITLE_MSG,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "DAILY_NOTIFICATION_TITLE_MSG", $$v)
                      },
                      expression: "item.DAILY_NOTIFICATION_TITLE_MSG",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-DAILY_NOTIFICATION_BODY_MSG",
                    label: "Daily Notification Description",
                    "label-for": "input-DAILY_NOTIFICATION_BODY_MSG",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-DAILY_NOTIFICATION_BODY_MSG",
                      type: "text",
                      required: "",
                      placeholder: "Enter Daily Notification Description",
                    },
                    model: {
                      value: _vm.item.DAILY_NOTIFICATION_BODY_MSG,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "DAILY_NOTIFICATION_BODY_MSG", $$v)
                      },
                      expression: "item.DAILY_NOTIFICATION_BODY_MSG",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-submit" },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "secondary" } },
                    [
                      _vm.isLoading
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _vm._e(),
                      _vm._v("Submit "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }